import _glslTokenizer from "glsl-tokenizer";
import _atobLite from "atob-lite";
var exports = {};
var tokenize = _glslTokenizer;
var atob = _atobLite;
exports = getName;

function getName(src) {
  var tokens = Array.isArray(src) ? src : tokenize(src);

  for (var i = 0; i < tokens.length; i++) {
    var token = tokens[i];
    if (token.type !== "preprocessor") continue;
    var match = token.data.match(/\#define\s+SHADER_NAME(_B64)?\s+(.+)$/);
    if (!match) continue;
    if (!match[2]) continue;
    var b64 = match[1];
    var name = match[2];
    return (b64 ? atob(name) : name).trim();
  }
}

export default exports;